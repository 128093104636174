import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PicturePreviewChatMessageComponent } from './picture-preview-chat-message.component';
import { MatMenuModule, MatProgressSpinnerModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';

@NgModule({
  declarations: [
    PicturePreviewChatMessageComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    NgbModule,
    ProgressSpinnerModule,
  ],
  exports: [
    PicturePreviewChatMessageComponent
  ]
})
export class PicturePreviewChatMessageModule { }
