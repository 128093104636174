import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FileUploadingService, fileTypeMappingEnum } from '../../services/file-uploading.service';

@Component({
  selector: 'm-picture-preview-chat-message',
  templateUrl: './picture-preview-chat-message.component.html',
  styleUrls: ['./picture-preview-chat-message.component.scss']
})
export class PicturePreviewChatMessageComponent implements OnInit {

  @Input() imageContentPreview: any = null;
  @Input() isDeleteAllowed = true;
  @Input() uploadType = 'image';

  @Output() imageRemoveEvent = new EventEmitter<any>();

  env = environment;
  imagePath = this.env.cdn_urls.image;
  fileTypeMappingObj = fileTypeMappingEnum;

  constructor(
    private fileUploadingService: FileUploadingService,
  ) { }

  async removeImage(fileUrl) {
    try {
      this.imageRemoveEvent.emit({ status: 'in_progress' });
      let fd = new FormData();
      fd.append('file_key', fileUrl);
      fd.append('upload_type', this.fileTypeMappingObj[this.uploadType]);
      await this.fileUploadingService.doDeleteUploadedFileAsynchronous(fd);
      this.imageRemoveEvent.emit({ status: 'sucesss' });
    }
    catch (error) {
      this.imageRemoveEvent.emit({ status: 'failed' });
    }
  }

  ngOnInit() {
  }

}
